<template>
  <div>
    <b-btn
      v-b-tooltip.hover
      variant="none"
      title="O download será feito conforme os filtros atualmente selecionados"
      class="btn btn-outline-primary"
      size="sm"
      :style="alterarStyle"
      @click="validateDonwload"
    >
      <font-awesome-icon icon="fa-solid fa-file-export" />
      Exportar excel
    </b-btn>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      descricao="Aguarde"
    />
  </div>
</template>

<script>
export default {
  components: {
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    especifico: {
      type: Boolean,
      default: false,
    },
    todasLinhas: {
      type: Array,
      default: () => [],
    },
    linhasSelecionadas: {
      type: Array,
      default: () => [],
    },
    filtro: {
      type: Object,
      default: () => [],
    },
    tipo: {
      type: String,
      default: '',
    },
    api: {
      type: Object,
      required: true,
    },
    alterarStyle: {
      type: String,
      default: 'padding: 0.55rem;',
    },
    getInfoExtra: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filtroManipulado: {},
    }
  },
  methods: {
    validateDonwload() {
      if (this.especifico) {
        this.baixarEmExcelEspecifico()
      } else {
        if (this.getInfoExtra !== undefined) {
          this.filtroManipulado = this.getInfoExtra()
        } else {
          this.filtroManipulado = this.filtro
        }
        this.baixarEmExcel()
      }
    },
    baixarEmExcel() {
      this.isLoading = true
      this.api.baixarEmExcel(this.tipo, this.filtroManipulado)
        .then(payload => {
          this.arquivo = payload.data
          window.open(this.arquivo.url)
          this.api.deletarExcel(this.arquivo.url, this.arquivo.nomeArquivo)
          this.arquivo.url = ''
          this.isLoading = false
          this.$sucesso('Exportação feita com sucesso')
        })
        .catch(err => {
          this.isLoading = false
          this.$erro(`Não foi possível fazer o download do arquivo. ${err.response.data.message}`)
          throw err
        })
    },
    baixarEmExcelEspecifico() {
      this.isLoading = true
      this.dto = ({
        ids: this.linhasSelecionadas,
        filtroOrdenacao: this.filtro,
        tipo: this.tipo,
        todosIds: this.todasLinhas,
      })
      this.api.baixarEmExcelEspecifico(this.dto)
        .then(payload => {
          this.arquivo = payload.data
          window.open(this.arquivo.url)
          this.api.deletarExcel(this.arquivo.url, this.arquivo.nomeArquivo)
          this.arquivo.url = ''
          this.isLoading = false
          this.$sucesso('Exportação feita com sucesso')
        })
        .catch(err => {
          this.isLoading = false
          this.$erro(`Não foi possível fazer o download do arquivo. ${err.response.data.message}`)
          throw err
        })
    },
  },
}
</script>
